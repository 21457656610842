import { AfterViewInit, ChangeDetectorRef, Component, inject, output, signal, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { Subject } from 'rxjs';
import { dialogConfig } from '@app/core/const/dialog.const';
import { ConfirmationDialogComponent } from '@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';
import { IConfirmationDialog } from '@app/shared/interfaces/confirmation-dialog.interface';
import { SignatureRequestService } from '@app/shared/services/signature-requests.service';
import { SignatureRequestStore } from '../../+data-access/signature-request.store';

export const CONFIRMATION_TEXT: IConfirmationDialog = {
  title: "Delete Signature",
  body: `
      <div class="fw-400 pt-8">Are you sure want to delete this signature?</div>`,
  btnCancel: "Cancel",
  btnSubmit: "Yes, Delete",
};
@Component({
  selector: 'app-signature-requests-sign-signature-saved',
  standalone: true,
  imports: [MatRadioModule, RoamIconComponent],
  template: `
    <div class="pt-20">
      @for (signature of signatureList(); track $index) {
        <div class="signature-option"
          [class.selected]="signature.isChecked">
          <mat-radio-button [value]="signature" [checked]="signature.isChecked"
            (change)="onSignatureChecked($event.value)" />

          @if (signature.text) {
            <p class="signature-text" [style.fontFamily]="signature.fontFamily">{{ signature.text }}</p>
          } @else {
            <div class="signature-text">
              <img [src]="signature.fileUrl" [style.maxWidth]="'300px'" />
            </div>
          }

          <div class="p-8 c-pointer">
            <roam-icon name="bin" fillColor="#666666" size="20" (onClick)="onDelete(signature.id)" />
          </div>
        </div>
      }
    </div>
  `,
  styleUrls: ['./signature-requests-sign-signature-saved.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignatureRequestsSignSignatureSavedComponent implements AfterViewInit {
  #dialog = inject(MatDialog);
  #cdRef = inject(ChangeDetectorRef);
  #signatureRequestService = inject(SignatureRequestService);
  signatureRequest = inject(SignatureRequestStore);

  selectedSignature = output<any>();

  signatureList = signal<any[]>([]);

  destroy$ = new Subject();

  constructor() {
    this.#getSignature();
  }

  ngAfterViewInit() {
    this.#cdRef.detectChanges();
  }

  #getSignature(): void {
    this.#signatureRequestService.getSignature()
      .subscribe({
        next: (resp) => {
          this.signatureList.set(resp.map((signature: any, index: number) =>
          ({
            ...signature,
            isChecked: (this.signatureRequest.signature.selected()?.id === signature.id || (!this.signatureRequest.signature.selected() && index === 0)) ? true : false
          })
          ));
          this.selectedSignature.emit(this.signatureList().filter(t => t.isChecked).at(0));
        },
      });
  }

  onSignatureChecked(data: any): void {
    this.signatureList.update((oldValue) => oldValue.map(signature => ({ ...signature, isChecked: signature.id === data.id })));
    this.selectedSignature.emit(this.signatureList().filter(t => t.isChecked).at(0));
  }

  onDelete(id: string): void {
    this.#dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirm,
        data: {
          confirmation: CONFIRMATION_TEXT,
        },
      })
      .afterClosed()
      .subscribe(val => {
        if (val) {
          this.#deleteSignature(id);
        }
      });
  }

  #deleteSignature(id: string): void {
    this.#signatureRequestService.deleteSignature(id)
      .subscribe({
        next: (resp) => {
          /* TODO: add success alert */
          this.#getSignature();
        },
      });
  }
}

import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SignatureRequestService {
    #apiUrl = `${environment.apiUrl}/signature-requests`;
    #apiUrlMe = `${environment.apiUrl}/me/signatures`;
    #http = inject(HttpClient);

    signatureRequestSearch({
        limit,
        page,
        sort,
        order,
        filters,
        manager,
        property,
    }: {
        limit: number;
        page: number;
        sort: string;
        order: "asc" | "desc";
        filters: Record<string, any>;
        manager: string;
        property: string;
    }): Observable<any> {
        return this.#http.post(this.#apiUrl + '/search',
            {
                limit,
                page,
                sort,
                order,
                filters,
                manager,
                property,
            }, {
            withCredentials: true
        });
    }

    signatureRequestCreate(bodyReq: any): Observable<any> {
        return this.#http.post(this.#apiUrl, bodyReq, {
            withCredentials: true
        });
    }

    signatureRequestDetail(id: string): Observable<any> {
        return this.#http.get(`${this.#apiUrl}/${id}`, {
            withCredentials: true
        });
    }

    signatureRequestUpdate(id: string, bodyReq: any): Observable<any> {
        return this.#http.patch(`${this.#apiUrl}/${id}`, bodyReq, {
            withCredentials: true
        });
    }

    signatureRequestDelete(id: string): Observable<any> {
        return this.#http.delete(`${this.#apiUrl}/${id}`, {
            withCredentials: true
        });
    }

    getSignature(): Observable<any> {
        return this.#http.get(this.#apiUrlMe, {
            withCredentials: true
        });
    }

    addSignature(bodyReq: any): Observable<any> {
        return this.#http.post(this.#apiUrlMe, bodyReq, {
            withCredentials: true
        });
    }

    deleteSignature(id: string): Observable<any> {
        return this.#http.delete(`${this.#apiUrlMe}/${id}`, {
            withCredentials: true
        });
    }

    signatureRequestTypes(): Observable<any> {
        return this.#http.get(`${environment.apiUrl}/signature-request-types`, {
            withCredentials: true
        });
    }

    signatureRequestDetailTypes(): Observable<any> {
        return this.#http.get(`${environment.apiUrl}/signature-request-detail-types`, {
            withCredentials: true
        });
    }
}
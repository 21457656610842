import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { PDFDocument, rgb } from 'pdf-lib';
import { merge, tap } from "rxjs";
import { SignatureRequestsSignSignatureComponent } from "../components/signature-requests-sign-signature";
import { SignatureRequestsSignDatepickerComponent } from "../components/signature-requests-sign-datepicker";

export interface ISignature {
    userId: string;
    groupId: string;
    propertyId: string;
    name: string;
    signatureRequestTypeId: string;
    description: string;
    dueDate: string;
    signers: string[];
    fileId: string;
    attachment: any;
    details: {
        signatureRequestDetailTypeId: string;
        pageNumber: number;
        x: number;
        y: number;
        value: string;
        width: string;
        height: string;
        detailType?: any;
    }[];
    file?: any;
    user?: any;
    type?: any;
}

@Injectable({
    providedIn: "root",
})
export class SignatureRequestStore {
    #dialog = inject(MatDialog);
    destroyRef = inject(DestroyRef);
    userConfig = inject(UserConfigStore);

    offsetX = signal<number | null>(null);
    offsetY = signal<number | null>(null);
    textLayer = signal<Element | null>(null);
    pageNumber = signal<number>(1);
    isDragging = signal<boolean>(false);
    isResizing = signal<boolean>(false);
    signers = signal<any[]>([]);
    propertyId = signal<string>('');
    managerId = signal<string>('');
    fileTypeSelected = signal<string>('');
    signatureBodyReq = signal<ISignature | null>(null);

    date = {
        value: signal<string>(''),
    }
    signature = {
        selected: signal<any>(null),
    }
    reloadTable = {
        all: signal<boolean>(false),
        awaiting: signal<boolean>(false),
        signatureRequest: signal<boolean>(false),
    }

    constructor() {
        const association$ = this.userConfig.selectedAssociationId$.pipe(
            tap((resp: any) => {
                this.propertyId.set(resp || '')
            })
        );
        const manager$ = this.userConfig.selectedManagerId$.pipe(
            tap((resp: any) => {
                this.managerId.set(resp || '')
            })
        )

        merge(association$, manager$)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    initCustomDiv(text: string = '', field: any, detailValue: any = null): void {
        let WIDTH = 'auto', HEIGHT = 'auto', ELEMENT_TYPE = 'div';
        const TEXT_BOX = ['initials', 'date-signed', 'person', 'email', 'company', 'title', 'textbox'];
        // const DIV = ['checkbox', 'dropdown', 'radio'];

        if (TEXT_BOX.includes(field.slug)) ELEMENT_TYPE = 'input', WIDTH = '125px';
        else if (field.slug === 'signature') ELEMENT_TYPE = 'div', WIDTH = `${(detailValue?.width || 125)}px`, HEIGHT = `${(detailValue?.height || 60)}px`;

        // Create the custom text element
        const customText = document.createElement(ELEMENT_TYPE);
        customText.classList.add('custom-text');
        customText.style.left = `${this.offsetX()}px`;
        customText.style.top = `${this.offsetY()}px`;
        customText.style.width = WIDTH;
        customText.style.height = HEIGHT;
        customText.setAttribute('contenteditable', 'true');
        customText.setAttribute('fieldType', field.slug);
        customText.setAttribute('fieldIndex', (this.signatureBodyReq()?.details?.length || 0)?.toString());

        if (TEXT_BOX.includes(field.slug)) {
            (customText as HTMLInputElement).type = 'text';
            (customText as HTMLInputElement).value = text || field.name;
        } else if (['date-signed', 'signature'].includes(field.slug)) {
            (customText as HTMLInputElement).readOnly = true;
            customText.setAttribute('contenteditable', 'false');

            if (field.slug === 'signature')
                customText.style.border = '2px solid #222222';
        } else {
            customText.innerText = text || field.name;
        }

        this.signatureBodyReq.update((oldValue: any) => {
            return {
                ...oldValue,
                details: [
                    ...oldValue?.details || [],
                    {
                        signatureRequestDetailTypeId: field.id,
                        pageNumber: this.pageNumber(),
                        x: this.offsetX(),
                        y: this.offsetY(),
                        value: text,
                    },
                ],
            };
        });

        customText.addEventListener('click', () => {
            document.querySelectorAll('.custom-text').forEach(el => el.classList.remove('selected'));
            customText.classList.add('selected');

            this.fileTypeSelected.set(customText.getAttribute('fieldType') || '');

            if (detailValue) {
                if (field.slug === 'date-signed')
                    this.#dialog.open(SignatureRequestsSignDatepickerComponent, {
                        backdropClass: 'roam-dialog-backdrop',
                        panelClass: 'roam-dialog-panel',
                        width: '300px',
                    })
                else if (field.slug === 'signature')
                    this.#dialog.open(SignatureRequestsSignSignatureComponent, {
                        backdropClass: 'roam-dialog-backdrop',
                        panelClass: 'roam-dialog-panel',
                        width: '700px',
                    })
            }
        });

        if (!detailValue) {
            // Make text draggable
            this.makeDraggable(customText);

            if (field.slug === 'signature')
                this.makeResizable(customText);
        }

        // Append text to the text layer of the page
        this.textLayer()?.appendChild(customText);
    }

    makeDraggable(element: HTMLElement): void {
        let offsetX = 0, offsetY = 0, isDragging = false;

        // Ensure the element stays inside the PDF page
        const page = document.querySelector(`.page[data-page-number="${this.pageNumber()}"]`);
        if (!page) return;

        const pageRect = page.getBoundingClientRect();

        element.addEventListener('mousedown', (event) => {
            isDragging = true;
            this.isDragging.set(isDragging);
            offsetX = event.clientX - element.getBoundingClientRect().left;
            offsetY = event.clientY - element.getBoundingClientRect().top;
        });

        document.addEventListener('mousemove', (event) => {
            if (!isDragging) return;

            // Calculate new position inside the PDF page
            let newX = event.clientX - pageRect.left - offsetX;
            let newY = event.clientY - pageRect.top - offsetY;

            // Keep the element within the bounds of the page
            newX = Math.max(0, Math.min(pageRect.width - 50, newX)); // 50 is estimated element width
            newY = Math.max(0, Math.min(pageRect.height - 20, newY)); // 20 is estimated element height

            element.style.left = `${newX}px`;
            element.style.top = `${newY}px`;

            this.fileTypeSelected.set(element.getAttribute('fieldType') || '');
            this.signatureBodyReq.update((oldValue: any) => {
                return {
                    ...oldValue,
                    details: oldValue?.details.map((detail: any, index: number) =>
                        index === Number(element.getAttribute('fieldIndex')) ? { ...detail, x: newX, y: newY } : detail
                    ),
                };
            });
        });

        document.addEventListener('mouseup', () => {
            isDragging = false;
            setTimeout(() => this.isDragging.set(isDragging));
        });
    }

    makeResizable(element: HTMLElement): void {
        if (element.querySelector('.resizer')) return; // Prevent duplicate resizers

        const resizer = document.createElement('div');
        resizer.classList.add('custom-resizer');
        element.appendChild(resizer);

        let isResizing = false;

        resizer.addEventListener('mousedown', (e) => {
            e.stopPropagation();
            isResizing = true;
            this.isResizing.set(true);
            document.addEventListener('mousemove', resizeElement);
            document.addEventListener('mouseup', stopResize);
        });

        const resizeElement = (e: MouseEvent) => {
            if (!isResizing) return;

            const newWidth = e.clientX - element.getBoundingClientRect().left;
            const newHeight = e.clientY - element.getBoundingClientRect().top;
            element.style.width = `${newWidth}px`;
            element.style.height = `${newHeight}px`;

            this.fileTypeSelected.set(element.getAttribute('fieldType') || '');
            this.signatureBodyReq.update((oldValue: any) => {
                return {
                    ...oldValue,
                    details: oldValue?.details.map((detail: any, index: number) =>
                        index === Number(element.getAttribute('fieldIndex')) ? { ...detail, width: newWidth, height: newHeight } : detail
                    ),
                };
            });
        }

        const stopResize = () => {
            isResizing = false;
            document.removeEventListener('mousemove', resizeElement);
            document.removeEventListener('mouseup', stopResize);

            setTimeout(() => this.isResizing.set(false));
        }
    }

    deleteSelectedElement(): void {
        const selectedElement = document.querySelector('.custom-text.selected') as HTMLElement;
        if (selectedElement) {
            const rect = selectedElement.getBoundingClientRect();
            const parentRect = selectedElement.offsetParent?.getBoundingClientRect();

            const relativeX = parentRect ? rect.left - parentRect.left : rect.left;
            const relativeY = parentRect ? rect.top - parentRect.top : rect.top;

            this.signatureBodyReq.update((oldValue: any) => {
                return {
                    ...oldValue,
                    details: oldValue?.details.filter((detail: any) =>
                        Math.round(detail.x) !== Math.round(relativeX) && Math.round(detail.y) !== Math.round(relativeY)
                    ),
                };
            });

            selectedElement.remove();
            this.fileTypeSelected.set('');
        }
    }

    async downloadUpdatedPDF(fileUrl: string) {
        const existingPdfBytes = await fetch(fileUrl).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const pages = pdfDoc.getPages();

        document.querySelectorAll('.page').forEach((pageElement, index) => {
            const page = pages[index];
            if (!page) return;

            // Find custom text elements inside the page
            pageElement.querySelectorAll<HTMLElement>('.custom-text').forEach((textElement: HTMLElement) => {
                const rect = textElement.getBoundingClientRect();  // Get absolute position
                const pageRect = pageElement.getBoundingClientRect(); // Get page position

                const scaleX = page.getWidth() / pageRect.width;  // Scale factor
                const scaleY = page.getHeight() / pageRect.height;

                const x = (rect.left - pageRect.left) * scaleX;
                const y = (page.getHeight() - (rect.top - pageRect.top) * scaleY) - 20; // Flip y-axis

                page.drawText(textElement.innerText, {
                    x,
                    y,
                    size: 14,
                    color: rgb(1, 0, 0),
                });
            });
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'modified-document.pdf';
        link.click();
    }
}
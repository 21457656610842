import { A11yModule } from "@angular/cdk/a11y";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  model,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { SignatureRequestStore } from "../../+data-access/signature-request.store";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-signature-requests-sign-datepicker',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
        .mat-mdc-dialog-content {
          border-top: 1px solid #dadada;
        }

        .confirm-button {
          border-radius: 1rem !important;
          height: 40px !important;
        }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  imports: [
    A11yModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule
  ],
  template: `
    <div class="dialog-header">
      <h2 mat-dialog-title>
        Date
      </h2>
    </div>
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-dialog-content>
      <mat-calendar [(selected)]="selectedDate"></mat-calendar>
    </mat-dialog-content>
    <mat-dialog-actions>
      <a class="cancel-button" (click)="onCancel()">
        <span class="label">Cancel</span>
      </a>
      <button
        mat-flat-button
        class="confirm-button"
        (click)="onNext()">
        Next
      </button>
    </mat-dialog-actions>
  `,
})
export class SignatureRequestsSignDatepickerComponent {
  dialogRef = inject(MatDialogRef<SignatureRequestsSignDatepickerComponent>);
  signatureRequest = inject(SignatureRequestStore);
  #datePipe = inject(DatePipe);

  selectedDate = model<Date>();

  constructor() {
    this.signatureRequest.date.value.set('');
  }

  onNext(): void {
    this.signatureRequest.date.value.set(this.#datePipe.transform(this.selectedDate(), 'MM/dd/yyyy')?.toString() || '');
    this.dialogRef.close();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

import { A11yModule } from "@angular/cdk/a11y";
import {
  Component,
  inject,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { SignatureRequestsSignSignatureSavedComponent } from "../signature-requests-sign-signature-saved";
import { SignatureRequestsSignSignatureTypeComponent } from "../signature-requests-sign-signature-type";
import { SignatureRequestsSignSignatureDrawComponent } from "../signature-requests-sign-signature-draw";
import { SignatureRequestsSignSignatureUploadComponent } from "../signature-requests-sign-signature-upload";
import { SignatureRequestStore } from "../../+data-access/signature-request.store";
import { SignatureRequestService } from "@app/shared/services/signature-requests.service";

@Component({
  selector: 'app-signature-requests-sign-signature',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
        .mdc-label {
          text-transform: capitalize !important;
        }

        .mat-mdc-dialog-content {
          border-top: 1px solid #dadada;
        }

        .confirm-button {
          border-radius: 1rem !important;
          height: 40px !important;
        }
    `,
  ],
  imports: [
    A11yModule,
    MatDialogModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    SignatureRequestsSignSignatureSavedComponent,
    SignatureRequestsSignSignatureTypeComponent,
    SignatureRequestsSignSignatureDrawComponent,
    SignatureRequestsSignSignatureUploadComponent,
  ],
  template: `
    <div class="dialog-header align-center justify-between">
      <h2 mat-dialog-title>
        Add Signature
      </h2>
      <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    
    <mat-dialog-content>
      <mat-tab-group class="mat-tab-chip" [@.disabled]="true"
        (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab label="Saved Signatures">
          @if (selectedTabIndex() === 0) {
            <app-signature-requests-sign-signature-saved
              (selectedSignature)="selectedSignatureHandler($event)" />
          }
        </mat-tab>
        <mat-tab label="Type">
          @if (selectedTabIndex() === 1) {
            <app-signature-requests-sign-signature-type
              (selectedSignature)="selectedSignatureHandler($event)" />
          }
        </mat-tab>
        <mat-tab label="Draw">
          @if (selectedTabIndex() === 2) {
            <app-signature-requests-sign-signature-draw
              (selectedSignature)="selectedSignatureHandler($event)" />
          }
        </mat-tab>
        <mat-tab label="Upload">
          @if (selectedTabIndex() === 3) {
            <app-signature-requests-sign-signature-upload
              (selectedSignature)="selectedSignatureHandler($event)" />
          }
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions>
      <a class="cancel-button" mat-dialog-close>
        <span class="label">Cancel</span>
      </a>
      <button
        mat-flat-button
        class="confirm-button"
        [disabled]="isLoading()"
        (click)="onAddThis()">
        Add This
      </button>
    </mat-dialog-actions>
  `,
})
export class SignatureRequestsSignSignatureComponent {
  #signatureRequestService = inject(SignatureRequestService);
  dialogRef = inject(MatDialogRef<SignatureRequestsSignSignatureComponent>);
  signatureRequest = inject(SignatureRequestStore);

  isLoading = signal<boolean>(false);
  selectedTabIndex = signal<number>(0);
  selectedSignature = signal<any>(null);

  constructor() {
    this.signatureRequest.date.value.set('');
  }

  selectedIndexChange(index: number): void {
    this.selectedTabIndex.set(index);
    this.signatureRequest.signature.selected.set(null);
  }

  selectedSignatureHandler(signature: any): void {
    this.selectedSignature.set(signature);
  }

  onAddThis(): void {
    if (this.selectedSignature()?.id && !this.selectedSignature()?.diskName) {
      this.signatureRequest.signature.selected.set(this.selectedSignature());
      this.dialogRef.close();
      return
    }

    if (this.selectedSignature()?.fileUrl) {
      this.onAddImageSignature();
      return;
    }

    this.onAddTextSignature();
  }

  onAddImageSignature(): void {
    const bodyReq = {
      type: 'image',
      fileId: this.selectedSignature().id,
      fileUrl: this.selectedSignature().fileUrl,
    };

    this.isLoading.set(true);
    this.#signatureRequestService.addSignature(bodyReq)
      .subscribe({
        next: (resp) => {
          this.signatureRequest.signature.selected.set(resp);
          this.isLoading.set(false);
          this.dialogRef.close();
        },
        error: (err) => {
          console.log(err);
          this.isLoading.set(false);
        }
      });
  }

  onAddTextSignature(): void {
    const bodyReq = {
      type: 'text',
      text: this.selectedSignature().text,
      fontFamily: this.selectedSignature()?.fontFamily || 'inherit',
    };

    this.#signatureRequestService.addSignature(bodyReq)
      .subscribe({
        next: (resp) => {
          this.signatureRequest.signature.selected.set(resp);
          this.isLoading.set(false);
          this.dialogRef.close();
        },
        error: (err) => {
          console.log(err);
          this.isLoading.set(false);
        }
      });
  }
}

import { Component, effect, inject, model, output, signal, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';
import { RoamSelectComponent } from '@app/shared/components/roam-select/roam-select.component';
import { FONT_LIST, IFontType } from './signature-requests-sign-signature-type.const';
import { SignatureRequestStore } from '../../+data-access/signature-request.store';

@Component({
  selector: 'app-signature-requests-sign-signature-type',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    RoamSelectComponent,
    RoamIconComponent
  ],
  template: `
    <div class="pt-20">
      <div class="w-50 mb-16">
        <app-roam-select
          [placeholder]="'Font'"
          [items]="fontOptions()"
          (selectedItem)="onFontSelected($event)" />
      </div>

      <div class="signature-option">
        <button
          class="btn btn-o-primary align-center gap-8 font-14px mr-0 fw-500 roam-b-none"
          [style.background-color]="'transparent'"
          (click)="onClear()">
          <roam-icon name="close" size="18" color="primary" />
          Clear
        </button>

        <mat-form-field appearance="fill" class="signature-field">
          <input matInput [(ngModel)]="signatureValue"
            [style.font-family]="fontSelected()?.fontFamily" />
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: ['./signature-requests-sign-signature-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignatureRequestsSignSignatureTypeComponent {
  selectedSignature = output<any>();

  signatureValue = model<string>('');
  fontSelected = model<IFontType | null>(null);
  fontOptions = signal<IFontType[]>(FONT_LIST);

  constructor() {
    effect(() => {
      if (this.signatureValue())
        this.selectedSignature.emit({
          fontFamily: this.fontSelected()?.fontFamily,
          text: this.signatureValue(),
          type: 'text',
        });
    });
  }

  onFontSelected(id: number): void {
    if (!id) return;

    this.fontSelected.set(
      this.fontOptions().find((font: IFontType) => font.id === id) || null
    );
  }

  onClear(): void {
    this.signatureValue.set('');
  }
}

export interface IFontType {
  id: number;
  name: string;
  fontFamily: string;
}

export const FONT_LIST: IFontType[] = [
  {
    id: 1,
    name: 'Arial',
    fontFamily: 'Arial, sans-serif',
  },
  {
    id: 2,
    name: 'Calibri',
    fontFamily: 'Calibri, sans-serif',
  },
  {
    id: 3,
    name: 'La Belle Aurore',
    fontFamily: '"La Belle Aurore", cursive',
  },
  {
    id: 4,
    name: 'Lato',
    fontFamily: '"Lato", sans-serif',
  },
  {
    id: 5,
    name: 'Mr De Haviland',
    fontFamily: '"Mr De Haviland", cursive',
  },
  {
    id: 6,
    name: 'Nothing You Could Do',
    fontFamily: '"Nothing You Could Do", cursive',
  },
  {
    id: 7,
    name: 'Over The Rainbow',
    fontFamily: '"Over the Rainbow", cursive',
  },
  {
    id: 8,
    name: 'Playball',
    fontFamily: '"Playball", cursive',
  },
  {
    id: 9,
    name: 'Times New Roman',
    fontFamily: '"Times New Roman", Times, serif',
  },
];
import { DatePipe } from '@angular/common';
import { Component, computed, DestroyRef, effect, inject, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthUser } from '@app/core/auth/auth-user';
import { UserConfigStore } from '@app/core/user-config/+data-access';
import { AttachmentType } from '@app/pages/financials/shared/const/attachment-type.const';
import { FileConfigModule } from '@app/shared/components/file-config/file-config.module';
import { FileUploaderComponent } from '@app/shared/components/file-uploader';
import { GlobalFormStore } from '@app/shared/dialogs/global-form/+data-access/global-form.store';
import { LocalStorageApi } from '@app/shared/services/local-storage.service';

@Component({
  selector: 'app-signature-requests-sign-signature-upload',
  standalone: true,
  imports: [DatePipe, FileUploaderComponent, FileConfigModule],
  template: `
    <h6 class="title mt-12 mb-8">Files</h6>
    <div class="d-flex gap-16 f-column mb-16">
      @for (file of attachments(); track $index) {
        <app-file-card-bar
          [name]="file.name"
          [date]="(file.createdAt | date: 'MMMM dd, YYYY') || ''"
          [user]="uploadBy"
          [size]="file.size"
          (onDelete)="deleteFile(file.id)" />
      }
    </div>

    <h6 class="title mb-8">Attachments</h6>
    <app-file-uploader containerText="Drag Here"
      [isShowFile]="false"
      acceptExtensions="image/*, .jpg, .jpeg, .png"
      descriptionText="Please upload a file in JPG, JPEG, or PNG format."
      [(data)]="attachments"
      [config]="modelConfig()" />
  `
})
export class SignatureRequestsSignSignatureUploadComponent {
  #destroyRef = inject(DestroyRef);
  #storage = inject(LocalStorageApi);
  #userConfig = inject(UserConfigStore);
  #globalStore = inject(GlobalFormStore);

  selectedSignature = output<any>();

  uploadBy: string = '';
  attachments = signal<any[]>([]);
  propertyId = signal<string>('');
  modelConfig = computed(() => {
    return {
      propertyId: this.propertyId()!,
      modelId: crypto.randomUUID(),
      model: AttachmentType.COMMUNICATION,
    };
  });

  constructor() {
    effect(() => {
      if (this.attachments().length > 0) this.selectedSignature.emit(this.attachments().at(0));
    }, {
      allowSignalWrites: true,
    });

    this.uploadBy = this.#storage.getItem<AuthUser>('user')?.name ?? '';
    this.#userConfig.selectedAssociationId$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(resp => {
        this.propertyId.set(resp || "");
      });
  }

  deleteFile(id: string): void {
    this.#globalStore.deleteFile(id)
      .subscribe({
        next: () => {
          this.attachments.update((file) => {
            return file.filter(t => t.id !== id);
          });
        },
        error: () => {
          // TODO: alert user
        },
      });
  }
}

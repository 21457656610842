import { AfterViewInit, Component, HostListener, inject, output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AttachmentType } from '@app/pages/financials/shared/const/attachment-type.const';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';
import { GlobalFormStore } from '@app/shared/dialogs/global-form/+data-access/global-form.store';

@Component({
  selector: 'app-signature-requests-sign-signature-draw',
  standalone: true,
  imports: [RoamIconComponent],
  template: `
    <div class="pt-20">
      <div class="signature-option">
        <button
          class="btn btn-o-primary align-center gap-8 font-14px mr-0 fw-500 roam-b-none"
          [style.background-color]="'transparent'"
          (click)="onClear()">
          <roam-icon name="close" size="18" color="primary" />
          Clear
        </button>

        <canvas #sigPad
          (mousedown)="onMouseDown($event)"
          (mousemove)="onMouseMove($event)"
          (mouseup)="onSave()"></canvas>
      </div>
    </div>
  `,
  styles: `
    app-signature-requests-sign-signature-draw {
      .signature-option {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 16px;
        border: 1px solid #ccc;
        border-radius: 8px;
        background-color: #f5f5f5;
      }
      canvas {
        width: 95%;
        max-height: 125px;
        margin: auto;
        border: 1px solid #ccc;
      }
    }
  `,
  encapsulation: ViewEncapsulation.None,
})
export class SignatureRequestsSignSignatureDrawComponent implements AfterViewInit {
  #globalStore = inject(GlobalFormStore);

  selectedSignature = output<any>();

  protected sigPadElement: any;
  protected context: any;
  protected img: any;
  protected isDrawing = false;

  @ViewChild('sigPad') sigPad: any;

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any) {
    this.isDrawing = false;
  }

  constructor() {
    const recordId = crypto.randomUUID();
    this.#globalStore.modelId.set(recordId);
    this.#globalStore.attachmentModel.set(AttachmentType.COMMUNICATION);
  }

  ngAfterViewInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#222222';
  }

  onMouseDown(e: any) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e: any) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event: any) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  onClear(): void {
    setTimeout(() => {
      this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    }, 1000);
  }

  onSave() {
    this.img = this.sigPadElement.toDataURL("image/png");
    const file = this.convertBase64ToFile(this.img, 'Draw_File.png');

    this.#globalStore.uploadFileService(file).subscribe({
      next: resp => {
        if (resp.length) this.selectedSignature.emit(resp.at(0).result);
      }
    });
  }

  convertBase64ToFile(base64String: string, fileName: string): File {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }
}
